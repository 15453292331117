.business-image {
  height: 135px;
  margin: 10px;
  width: 100px;
}

.card {
  border: 1px solid #000000;
  border-radius: 10px;
  display: flex;
  height: 200px;
  filter: drop-shadow(0px 4px 4px rgba(200,201,209,0.58));
  letter-spacing: 2px;
  margin: 20px;
  width: 400px;
}
.cardHeader {
  color: #2d42ba;
  font-family: 'Karla', sans-serif;
  background-color: #FFF4DB;
  font-size: 20px;
  line-height: 22px;
  text-align: left;
  margin-bottom: 9px;
  margin-top: 10px;
}
.cardBody {
  color: #93959F;
  background-color: #FFF4DB;
  font-size: 11px;
  text-align: left;
  text-transform: uppercase;
  line-height: 16px;
}
.need-indicator {
  position: absolute;
  right: 11px;
  bottom: 11px;
}
